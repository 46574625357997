import { Box } from '@chakra-ui/react';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import AppPageContainer from '../../components/AppPageContainer';
import ToolBar from '../../components/ToolBar';
import { Auth, fetchUser } from '../../modules/auth_module';
import { fetchQuotes, Quotes } from '../../modules/offsets_module';
import { Doughnut } from 'react-chartjs-2';
import { Keys } from '../../modules/keys_module';


const DashboardPage = () => {
    const auth = Auth.use();
    const keys = Keys.use();
    const quotes = Quotes.use();

    useEffect(() => {
        fetchQuotes(keys.activeKey);
        fetchUser();
    }, [])

    const appendLabel = (text) => ((context) => {
        console.log(context)
        return context.formattedValue += text
    })

    const data_quotes_count = {
        labels: [ "Untracked Quotes", "Tracked Emissions", "Purchased Offset Quotes"],
        datasets: [
            {
                label: 'Quotes',
                backgroundColor: [
                    '#F8C749',
                    '#00AF70',
                    '#168CCE',
                ],
                data: [
                    quotes.quotes.filter(x => x.Status == "QUOTE").length,
                    quotes.quotes.filter(x => x.Status == "TRACKED").length,
                    quotes.quotes.filter(x => x.Status == "PURCHASED").length,
                ],
            },
        ]
    };
    const data_quotes_sum = {
        labels: [ "Untracked Quotes", "Tracked Emissions", "Purchased Offset Quotes"],
        datasets: [
            {
                label: 'Quotes',
                backgroundColor: [
                    '#F8C749',
                    '#00AF70',
                    '#168CCE',
                ],
                data: [
                    quotes.quotes.filter(x => x.Status == "QUOTE").reduce((acc, curr) => acc + curr.carbon_impact, 0),
                    quotes.quotes.filter(x => x.Status == "TRACKED").reduce((acc, curr) => acc + curr.carbon_impact, 0),
                    quotes.quotes.filter(x => x.Status == "PURCHASED").reduce((acc, curr) => acc + curr.carbon_impact, 0),
                ],
            },
        ]
    }
    // TODO: put the data in state and wait for the page to stop fuckin' rerendering before showing them
    return (
        <AppPageContainer activePage="dashboard">
            { auth.loggedIn && (
                <>
                    <ToolBar pageName="Your Dashboard" />   
                    <div className="mt-4 grid grid-cols-3 gap-4">
                        <div className="p-4 bg-gray-100 rounded-md">
                            <h2>Quantity of Carbon Quote Statuses</h2>
                            <Doughnut data={data_quotes_count} />
                        </div>
                        <div className="p-4 bg-gray-100 rounded-md">
                            <h2>Carbon Impact Statuses</h2>
                            <Doughnut data={data_quotes_sum} options={{ plugins: { tooltip: { callbacks: { label: appendLabel('kg')}}}}} />
                        </div>
                    </div>
                </>
            )}
        </AppPageContainer>
    );
};

export default DashboardPage;
