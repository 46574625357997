import { entity } from 'simpler-state';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    timeout: 2000,
});

export const Quotes = entity({
    loading: false,
    error: true,
    quotes: [],
    errorMsg: null,
})

export const fetchQuotes = async (apiKey) => {
    Quotes.set( { ...Quotes.get(), loading: true });

    const result = await api.get(`/offsets/quotes`, { withCredentials: true, headers: { 'x-api-key': apiKey } });
    if (result.status !== 200) {
        Quotes.set({ ...Quotes.get(), loading: false, error: true, errorMsg: result.status })
    } else {
        Quotes.set({ ...Quotes.get(), loading: false, quotes: result.data })
    }
}

export const createQuote = async  (apiKey, data) => {
    data.carbon_impact = parseFloat(data.carbon_impact)
    const result = await api.post('/offsets/quotes', data, {withCredentials: true, headers: { 'x-api-key': apiKey }})
    if (result.status !== 200) {
        return null;
    } else {
        return result.data;
    }
}

export const confirmQuoute = async (apikey, id) => {
    const result = await api.post(`/offsets/quotes/${id}/confirm`, null, {withCredentials: true, headers: { 'x-api-key': apikey }})
    if (result.status !== 200) {
        return null;
    } else {
        return result.data
    }
}